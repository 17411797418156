<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="800px"
    style="z-index:200000001;"
  >
    <v-card>
      <v-card-title class="d-flex flex-row justify-between app-bold-font">
        Availability
        <v-btn icon @click="onClose"><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>
      <v-card-text class="app-regular-font">
        What's days and times you are available
      </v-card-text>
      <v-card-text>Select days:</v-card-text>
      <v-card-text
        class="d-flex justify-center flex-column mb-5 app-medium-font dark-font-color"
      >
        <v-row>
          <v-col v-for="item in days" :key="item" style="padding: 6px">
            <v-btn
              @click="selectDay(item)"
              :color="item == selectedDay ? '#b5e539' : 'white'"
              rounded
              block
            >
              {{ item }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text v-if="selectedDay">Select times:</v-card-text>
      <v-card-text
        class="d-flex mb-0"
        v-if="selectedDay"
        :class="$vuetify.breakpoint.xs ? 'flex-column' : 'flex-row'"
      >
        <vue-timepicker
          format="hh:mm a"
          v-model="startTime"
          :minute-interval="5"
          :class="$vuetify.breakpoint.xs ? 'w-100 mb-2' : ''"
        />
        <vue-timepicker
          format="hh:mm a"
          v-model="endTime"
          :minute-interval="5"
          :class="$vuetify.breakpoint.xs ? 'w-100 mb-2' : 'ml-5'"
        />
        <v-btn
          color="#b5e539"
          height="40"
          @click="onAddTime"
          :class="$vuetify.breakpoint.xs ? 'w-100 mb-2' : 'ml-5'"
        >
          Add time
        </v-btn>
      </v-card-text>
      <v-card-text class="mt-0 dark-font-color app-bold-font font-16"
        >Your schedule</v-card-text
      >
      <v-card-text>
        <span v-if="schedules.length == 0"
          >You have not chosen a date and time yet</span
        >
        <div v-else>
          <div
            v-for="schedule in schedules"
            :key="schedule"
            class="d-flex flex-row align-center mb-3"
          >
            <v-btn color="#b5e539" rounded>
              {{ getDay(schedule) }}
            </v-btn>
            <span style="font-size: 16px;" class="ml-6">
              {{ getScheduleTime(schedule) }}
            </span>
            <v-btn icon class="ml-3" @click="onRemoveSchedule(schedule)"
              ><v-icon>mdi-close</v-icon></v-btn
            >
          </div>
        </div>
      </v-card-text>
      <v-card-text v-if="message">
        <v-alert dense type="error" v-text="message" v-if="message" />
      </v-card-text>
      <v-card-actions
        style="display: flex; flex-direction: row; justify-content:center;"
        class="pb-6 mt-3"
      >
        <v-btn color="#b5e539" @click="onSave" width="150" :loading="loading">
          Continue
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapState } from "vuex";
import VueTimepicker from "vue2-timepicker";
import "vue2-timepicker/dist/VueTimepicker.css";
export default {
  components: { VueTimepicker },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    onClose: {
      type: Function,
    },
  },
  data() {
    return {
      message: null,
      loading: false,
      days: ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"],
      selectedDay: "",
      startTime: null,
      endTime: null,
      schedules: [],
    };
  },
  methods: {
    ...mapActions("auth", {
      updateProfile: "updateProfile",
    }),
    onChange() {
      this.message = null;
    },
    onSave() {
      if (this.schedules.length == 0) {
        this.message = "Please select at least one.";
        return;
      }
      this.loading = true;
      this.updateProfile({ availabilities: this.schedules })
        .then(() => {
          this.loading = false;
          this.onClose();
        })
        .catch((error) => {
          this.loading = false;
          this.message = error.response.data.message;
        });
    },
    selectDay(item) {
      this.selectedDay = item;
    },
    onAddTime() {
      if (!this.startTime || !this.endTime) {
        return;
      }
      if (!this.startTime.hh || !this.startTime.mm || !this.startTime.a) {
        return;
      }
      if (!this.endTime.hh || !this.endTime.mm || !this.endTime.a) {
        return;
      }
      this.schedules.push(
        `${this.selectedDay} ${this.startTime.hh}:${this.startTime.mm} ${this.startTime.a} - ${this.endTime.hh}:${this.endTime.mm} ${this.endTime.a}`
      );
      this.startTime = { hh: null, mm: null, a: null };
      this.endTime = { hh: null, mm: null, a: null };
    },
    getDay(schedule) {
      return schedule.split(" ")[0];
    },
    getScheduleTime(schedule) {
      return schedule.substring(4);
    },
    onRemoveSchedule(schedule) {
      this.schedules = this.schedules.filter((s) => s != schedule);
    },
  },
  computed: {
    ...mapState("auth", {
      profile: "profile",
    }),
  },
  watch: {
    selectedDay() {
      this.startTime = { hh: null, mm: null, a: null };
      this.endTime = { hh: null, mm: null, a: null };
    },
  },
};
</script>
<style scoped>
@media (max-width: 600px) {
  div >>> .v-dialog {
    margin: 0;
    /* padding-top: 17px; */
    /* padding-bottom: 17px; */
    /* height: 100%; */
  }
}
@media (min-width: 600px) {
  .v-dialog__content {
    z-index: 2000001 !important;
  }
  div >>> .v-dialog {
    /* height: 80vh; */
    width: 70%;
    max-width: 900px;
    overflow-y: hidden;
  }
}
.time-picker {
  width: 107px;
  border-color: #d094ea !important;
  border-width: 1px;
  height: 40px;
}
.time-picker >>> input.display-time {
  height: 40px !important;
  width: 100%;
  border-radius: 5px;
  border-color: rgba(0, 0, 0, 0.38);
  padding: 0 15px;
}
.time-picker >>> input.display-time:hover {
  border-color: currentColor;
}
.time-picker >>> input.display-time:focus-visible {
  border-color: #d094ea !important;
  border-width: 1px;
}
.time-picker >>> .clear-btn {
  padding: 5px 16px;
  margin-top: 5px;
}
</style>
