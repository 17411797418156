var render = function render(){var _vm=this,_c=_vm._self._c;return _c('keep-alive',[_c('v-container',{staticClass:"d-flex flex-column",staticStyle:{"height":"100%","padding":"0px","min-height":"inherit","max-width":"1500px"},attrs:{"fluid":""}},[_vm._l((_vm.notifications),function(notification){return _c('v-alert',{key:notification,staticStyle:{"margin-left":"100px","margin-right":"80px"},attrs:{"dismissible":"","type":"success"}},[_vm._v(" "+_vm._s(notification)+" ")])}),_vm._l((_vm.errorNotifications),function(notification){return _c('v-alert',{key:notification,staticStyle:{"margin-left":"100px","margin-right":"80px"},attrs:{"dismissible":"","type":"error"}},[_vm._v(" "+_vm._s(notification)+" ")])}),_c('div',{staticClass:"d-flex flex-row",staticStyle:{"height":"100%","padding":"0px","min-height":"inherit","max-width":"1500px"}},[_c('volunteer-left-section',{staticClass:"hidden-md-and-down"}),_c('feed-section'),_c('volunteer-right-section',{staticClass:"hidden-sm-and-down"})],1),_c('v-snackbar',{model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]),(
        _vm.verifyPhoneDialog &&
          !_vm.completeProfileDialog &&
          !_vm.causeDialog &&
          !_vm.interestsDialog &&
          !_vm.availabilitiesDialog &&
          !_vm.congratsDialog
      )?_c('phone-verification-dialog',{attrs:{"verifyDialog":_vm.verifyPhoneDialog,"onClose":_vm.onCloseVerify}}):_vm._e(),(
        !_vm.verifyPhoneDialog &&
          _vm.completeProfileDialog &&
          !_vm.causeDialog &&
          !_vm.interestsDialog &&
          !_vm.availabilitiesDialog &&
          !_vm.congratsDialog
      )?_c('complete-profile-dialog',{attrs:{"dialog":_vm.completeProfileDialog,"onClose":_vm.onCloseCompleteProfileDialog}}):_vm._e(),(
        !_vm.verifyPhoneDialog &&
          !_vm.completeProfileDialog &&
          _vm.causeDialog &&
          !_vm.interestsDialog &&
          !_vm.availabilitiesDialog &&
          !_vm.congratsDialog
      )?_c('volunteer-causes-dialog',{attrs:{"dialog":_vm.causeDialog,"onClose":_vm.onCloseCausesDialog}}):_vm._e(),(
        !_vm.verifyPhoneDialog &&
          !_vm.completeProfileDialog &&
          !_vm.causeDialog &&
          _vm.interestsDialog &&
          !_vm.availabilitiesDialog &&
          !_vm.congratsDialog
      )?_c('volunteer-interests-dialog',{attrs:{"dialog":_vm.interestsDialog,"onClose":_vm.onCloseInterestsDialog}}):_vm._e(),(
        !_vm.verifyPhoneDialog &&
          !_vm.completeProfileDialog &&
          !_vm.causeDialog &&
          !_vm.interestsDialog &&
          _vm.availabilitiesDialog &&
          !_vm.congratsDialog
      )?_c('volunteer-availabilities-dialog',{attrs:{"dialog":_vm.availabilitiesDialog,"onClose":_vm.onCloseAvailabilities}}):_vm._e(),(
        !_vm.verifyPhoneDialog &&
          !_vm.completeProfileDialog &&
          !_vm.causeDialog &&
          !_vm.interestsDialog &&
          !_vm.availabilitiesDialog &&
          _vm.congratsDialog
      )?_c('volunteer-congrates-dialog',{attrs:{"dialog":_vm.congratsDialog,"onClose":_vm.onCloseCongratsDialog}}):_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }