<template>
  <v-dialog v-model="dialog" persistent max-width="800px">
    <v-card>
      <v-card-title
        class="d-flex justify-between app-bold-font"
        :class="$vuetify.breakpoint.xs ? 'mt-4' : ''"
      >
        Select Interests
        <v-btn icon @click="onClose"><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>
      <v-card-text class="app-regular-font">
        What interests, talents, and skills do you have?
      </v-card-text>
      <v-card-text
        class="d-flex justify-center flex-column dark-font-color app-medium-font"
      >
        <v-row>
          <v-col
            cols="3"
            v-for="item in interests"
            :key="item.name"
            class="pa-1"
          >
            <v-card
              @click="selectInterest(item)"
              :class="{ 'selected-card': item.selected }"
              class="rounded-lg h-100"
            >
              <v-card-text
                class="d-flex flex-column justify-center align-center py-5"
              >
                <site-icon
                  :name="item.name"
                  type="Interest"
                  :size="$vuetify.breakpoint.xs ? '40' : '60'"
                />
                <span
                  class="dark-gray-color text-center"
                  :class="
                    $vuetify.breakpoint.xs
                      ? 'font-8 mobile-line-height mt-2'
                      : 'font-12 mt-1'
                  "
                >
                  {{ item.name }}</span
                >
              </v-card-text>
              <div
                class="position-absolute"
                :style="
                  $vuetify.breakpoint.xs
                    ? 'top: 2px; right: 2px'
                    : 'top: 10px; right:10px;'
                "
                v-if="item.selected"
              >
                <v-icon color="#6038f7" :size="$vuetify.breakpoint.xs ? 14 : 24"
                  >mdi-heart</v-icon
                >
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text v-if="message">
        <v-alert dense type="error" v-text="message" v-if="message" />
      </v-card-text>
      <v-card-actions
        class="pb-6 mt-3 d-flex justify-center"
        :class="{ 'mb-4': $vuetify.breakpoint.xs }"
      >
        <v-btn color="#b5e539" @click="onSave" width="150" :loading="loading"
          >Continue</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";
import SiteIcon from "@/components/common/SiteIcon.vue";
export default {
  components: { SiteIcon },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    onClose: {
      type: Function,
    },
  },
  data() {
    return {
      message: null,
      loading: false,
      interests: [],
    };
  },
  methods: {
    ...mapActions({
      updateProfile: "auth/updateProfile",
      getInterestIcons: "icon/getInterestIcons",
    }),
    onChange() {
      this.message = null;
    },
    onSave() {
      var params = [];
      params = this.interests.filter((c) => c.selected).map((c) => c.name);
      console.log(params);
      if (params.length == 0) {
        this.message = "Please select at least one.";
        return;
      }
      this.loading = true;
      this.updateProfile({ interests: params })
        .then(() => {
          this.loading = false;
          this.onClose();
        })
        .catch((error) => {
          this.loading = false;
          this.message = error.response.data.message;
        });
    },
    selectInterest(item) {
      item.selected = !item.selected;
      this.interests = [...this.interests];
    },
  },
  computed: {
    ...mapState("auth", {
      profile: "profile",
    }),
    ...mapGetters({
      allInterests: "icon/interests",
    }),
  },
  watch: {
    allInterests(newValue) {
      this.interests = [...newValue];
    },
  },
  mounted() {
    this.getInterestIcons().catch((error) => console.log(error));
  },
};
</script>
<style scoped>
@media (max-width: 600px) {
  div >>> .v-dialog {
    margin: 0;
  }
}
@media (min-width: 600px) {
  .v-dialog__content {
    z-index: 2000001 !important;
  }
}
.mobile-line-height {
  line-height: 12px;
}
.v-dialog::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.v-dialog::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px light-grey;
  border-radius: 3px;
}
/* Handle */
.v-dialog::-webkit-scrollbar-thumb {
  background: #f0a000;
  border-radius: 3px;
}

/* Handle on hover */
.v-dialog::-webkit-scrollbar-thumb:hover {
  background: #f0a000;
}

.selected-card {
  border: 1px solid #fcc000;
}
</style>
