<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="600px"
    style="z-index:200000001;"
  >
    <v-card>
      <v-card-title
        style="display: flex; flex-direction: row; justify-content: space-between; font-family: 'Poppins-SemiBold'"
      >
        Complete profile
        <v-btn icon @click="onClose"><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>
      <v-card-text style="font-family:'Poppins-Regular';">
        Just add your profile and a few words about you.
      </v-card-text>
      <v-card-text
        class="d-flex justify-center flex-column"
        style="font-family:'Poppins-Medium';color: #1e1e1e"
      >
        Select Profile
        <div class="d-flex justify-center">
          <editable-avatar :image="image" :onImageSelected="onImageSelected" />
        </div>
      </v-card-text>
      <v-card-text class="d-flex justify-center flex-column">
        <div style="font-family:'Poppins-Medium'; color: #1e1e1e">
          About me
        </div>
        <v-textarea
          rows="4"
          auto-grow
          label="Description"
          solo
          flat
          outlined
          class="mt-2 app-regular-font"
          v-model="about_me"
          :rules="[rules.required]"
          @input="onChange"
        />
      </v-card-text>
      <v-card-text v-if="message">
        <v-alert dense type="error" v-text="message" v-if="message" />
      </v-card-text>
      <v-card-actions
        style="display: flex; flex-direction: row; justify-content:center;"
        class="pb-6 mt-3"
      >
        <v-btn
          color="#b5e539"
          @click="onSave"
          class="white--text"
          width="150"
          :loading="loading"
          >Continue</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapState } from "vuex";
import EditableAvatar from "@/components/EditableAvatar.vue";
export default {
  components: { EditableAvatar },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    onClose: {
      type: Function,
    },
  },
  data() {
    return {
      code: "",
      about_me: "",
      image: "",
      message: null,
      loading: false,
      sendingCode: false,
      disableResend: false,
      resendCount: 0,
      rules: {
        required: (value) => !!value || "Required.",
      },
      selectedImage: null,
    };
  },
  methods: {
    ...mapActions("auth", {
      completeProfile: "completeProfile",
    }),
    onChange() {
      this.message = null;
    },
    onSave() {
      // if (!this.profile.image && !this.selectedImage) {
      //   this.message = "Please select profile picture.";
      //   return;
      // }
      if (!this.about_me) {
        this.message = "Please add description.";
        return;
      }
      var formData = new FormData();
      if (this.selectedImage) {
        formData.append("image", this.selectedImage);
      }
      formData.append("about_me", this.about_me);
      this.loading = true;
      this.completeProfile(formData)
        .then(() => {
          this.loading = false;
          this.onClose();
        })
        .catch((error) => {
          this.loading = false;
          this.message = error.response.data.message;
        });
    },
    onImageSelected(image) {
      this.selectedImage = image;
      this.message = null;
    },
  },
  computed: {
    ...mapState("auth", {
      profile: "profile",
    }),
  },
  mounted() {
    this.about_me = this.profile.about_me;
    this.image = this.profile.midThumbnail
      ? this.profile.midThumbnail
      : this.profile.image;
  },
};
</script>
<style scoped></style>
